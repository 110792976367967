<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>模块</a-breadcrumb-item>
              <a-breadcrumb-item>套餐充值缴费</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <a-modal
          title="微信付款"
          :visible="visiblepay"
          @ok="handleOkpay"
          @cancel="handleCancelpay"
        >
          <div style="width: 100%; text-align: center">
            <img :src="QRlink" alt="" />
          </div>
        </a-modal>
        <div>
          <div class="flow-d-row">
            <span style="font-size: 16px">充值金额 :</span>
            <div style="padding-left: 10px" class="flow-d-row">
              <div
                @click="onmoney(10)"
                :class="form.amount == 10 ? 'pay-div divs' : 'pay-div'"
              >
                10元
              </div>
              <div
                @click="onmoney(20)"
                :class="form.amount == 20 ? 'pay-div divs' : 'pay-div'"
              >
                20元
              </div>
              <div
                @click="onmoney(50)"
                :class="form.amount == 50 ? 'pay-div divs' : 'pay-div'"
              >
                50元
              </div>
              <div
                @click="onmoney(100)"
                :class="form.amount == 100 ? 'pay-div divs' : 'pay-div'"
              >
                100元
              </div>
              <div
                class="flow-d-row"
                style="justify-content: space-around; width: 160px"
              >
                自定义<a-input
                  @focus="moneyfocus"
                  style="width: 80px; border-radius: 3px"
                  v-model="amounts"
                />元
              </div>
            </div>
          </div>
          <div style="margin-top: 25px" class="flow-d-row">
            <span style="font-size: 16px">充值方式 :</span>
            <div style="padding-left: 10px" class="flow-d-row">
              <div
                @click="onpayways(0)"
                style="
                  justify-content: center;
                  width: 140px;
                  margin-right: 100px;
                "
                :class="
                  form.payways == 0
                    ? 'paywaying flow-d-row'
                    : 'payway flow-d-row'
                "
              >
                <a-checkbox
                  style="margin-right: 8px"
                  :checked="form.payways == 0 ? true : false"
                >
                </a-checkbox>
                <img
                  style="width: 80px; height: 26px"
                  src="@/assets/svg/wx.png"
                  alt=""
                />
              </div>
              <div
                @click="onpayways(1)"
                style="justify-content: center; width: 140px"
                :class="
                  form.payways == 1
                    ? 'paywaying flow-d-row'
                    : 'payway flow-d-row'
                "
              >
                <a-checkbox
                  style="margin-right: 8px"
                  :checked="form.payways == 1 ? true : false"
                ></a-checkbox>
                <img
                  style="width: 80px; height: 26px"
                  src="@/assets/svg/ali.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <a-button
            @click="onsubmit"
            style="
              width: 240px;
              height: 35px;
              margin: 25px 0 0 83px;
              line-height: 35px;
            "
            type="primary"
          >
            立即充值
          </a-button>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '路径',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
  },
  {
    title: '模块包含子权限',
    dataIndex: 'permissions',
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import QRCode from 'qrcode'
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      visiblepay: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      value: 'name',
      key: '',
      selectedRowKey: [],
      Id: '',
      form: {
        amount: 10,
        payways: 0,
        subject: 'TEST',
      },
      amounts: '',
      QRlink: '',
    }
  },
  filters: {},
  methods: {
    handleOkpay() {
      console.log()
    },
    handleCancelpay() {
      this.visiblepay = false
    },
    onmoney(e) {
      this.form.amount = e
      this.amounts = ''
    },
    moneyfocus() {
      this.form.amount = 0
    },
    onpayways(e) {
      this.form.payways = e
    },
    onsubmit() {
      if (this.form.amount == 0) {
        this.form.amount = this.amounts
      }
      if (this.form.payways == 0) {
        this.getwxpayamount(this.form.amount, this.form.subject)
      } else {
        //支付宝
        this.getalipayamount(this.form.amount, this.form.subject)
      }
    },
    //支付宝
    async getalipayamount(amount, subject) {
      window.open(
        `http://192.168.1.184:8080/api/sccn/pay/alipay?amount=${amount}&subject=${subject}`
      )
    },
    //微信
    async getwxpayamount(amount, subject) {
      try {
        const res = await http.getwxpayamount(amount, subject)
        const { success, data } = res.data
        if (success) {
          this.visiblepay = true
          QRCode.toDataURL(
            data.code,
            {
              errorCorrectionLevel: 'L',
              margin: 2,
              width: 128,
            },
            (err, url) => {
              if (err) throw err
              this.QRlink = url
            }
          )
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async postxcxconfig(form) {
      try {
        const res = await http.postxcxconfig(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.getxcxconfig()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
    },
    async getxcxconfig() {
      try {
        const res = await http.getxcxconfig()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form.xcxAppId = data.xcxAppId.value
          this.form.xcxSecret = data.xcxSecret.value
          this.form.gzhAppId = data.gzhAppId.value
          this.form.gzhSecret = data.gzhSecret.value
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getxcxconfig()
      }, 100)
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getxcxconfig()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
span {
  display: inline-block;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pay-div {
  padding: 3px 17px;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  margin-right: 40px;
  color: #000;
}
.divs {
  background-color: #4a90e4;

  color: #fff;
}
.payway {
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}
.paywaying {
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #588bb3;
  border-radius: 3px;
}
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
